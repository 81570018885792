.video-popup {
  opacity: 0;
  pointer-events: none;
  transition: .3s all ease;

  .video-player-wrapper {
    opacity: 0;
    transform: translateY(50px);
    transition: .3s all ease;
  }

  &.is-active {
    opacity: 1;
    pointer-events: auto;

    .video-player-wrapper {
      opacity: 1;
      transform: none;
      transition-delay: .15s;
    }
  }
}