#main-header {
  .icon-open, .icon-close {
    transition: .3s all ease;
  }
  .icon-close {
    opacity: 0;
  }

  &.show-mobile-nav {
    .icon-open {
      opacity: 0;
    }
    .icon-close {
      opacity: 1;
    }
  }

  &.show-virlab-submenu #virlab-submenu {
    transform: none;
  }

  &.show-pocare-submenu #pocare-submenu {
    transform: none;
  }
}

.submenu-mobile {
  transform: translateX(100%);
  transition: .3s all ease-in-out;
}