/*! Flickity v3.0.0
https://flickity.metafizzy.co
---------------------------------------------- */
.flickity-enabled{position:relative}.flickity-enabled:focus{outline:0}.flickity-viewport{overflow:hidden;position:relative;height:100%;touch-action:pan-y}.flickity-slider{position:absolute;width:100%;height:100%;left:0}.flickity-rtl .flickity-slider{left:unset;right:0}.flickity-enabled.is-draggable{-webkit-tap-highlight-color:transparent;user-select:none}.flickity-enabled.is-draggable .flickity-viewport{cursor:move;cursor:grab}.flickity-enabled.is-draggable .flickity-viewport.is-pointer-down{cursor:grabbing}.flickity-cell{position:absolute;left:0}.flickity-rtl .flickity-cell{left:unset;right:0}.flickity-button{position:absolute;background:hsl(0 0% 100% / 75%);border:none;color:#333}.flickity-button:hover{background:#fff;cursor:pointer}.flickity-button:focus{outline:0;box-shadow:0 0 0 5px #19f}.flickity-button:active{opacity:.6}.flickity-button:disabled{opacity:.3;cursor:auto;pointer-events:none}.flickity-button-icon{fill:currentColor}.flickity-prev-next-button{top:50%;width:44px;height:44px;border-radius:50%;transform:translateY(-50%)}.flickity-prev-next-button.previous{left:10px}.flickity-prev-next-button.next{right:10px}.flickity-rtl .flickity-prev-next-button.previous{left:auto;right:10px}.flickity-rtl .flickity-prev-next-button.next{right:auto;left:10px}.flickity-prev-next-button .flickity-button-icon{position:absolute;left:20%;top:20%;width:60%;height:60%}.flickity-page-dots{position:absolute;width:100%;bottom:-25px;text-align:center;display:flex;justify-content:center;flex-wrap:wrap}.flickity-rtl .flickity-page-dots{direction:rtl}.flickity-page-dot{display:block;width:10px;height:10px;padding:0;margin:0 8px;background:hsl(0 0% 20% / 25%);border-radius:50%;cursor:pointer;appearance:none;border:none;text-indent:-9999px;overflow:hidden}.flickity-rtl .flickity-page-dot{text-indent:9999px}.flickity-page-dot:focus{outline:0;box-shadow:0 0 0 5px #19f}.flickity-page-dot.is-selected{background:hsl(0 0% 20% / 100%)}

.flickity-page-dots {
  position: relative;
  bottom: unset;
}

.flickity-prev-next-button.previous {
  left: 0;
}

.flickity-prev-next-button.next {
  right: 0;
}

.flickity-prev-next-button {
  width: 56px;
  height: 35px;
  border: 2px solid white;
  border-radius: 100px;
  background-color: transparent;
  transition: .3s all ease;
  svg path {
    fill: white;
    transition: .3s all ease;
  }
  &:hover {
    background-color: white;
    svg path {
      fill: theme('colors.blue'); 
    }
  }
}

#home-testimonials {
  .carousel {
    opacity: 0;
    transition: .3s opacity ease;
    &.is-selected {
      opacity: 1;
    }
  }
  .flickity-viewport {
    overflow: visible;
  }
  .flickity-button {
    display: none;
  }
  .flickity-page-dots {
    text-align: left;
    margin-top: 40px;
    justify-content: left;
  }
  .flickity-page-dot {
    background-color: white;
    opacity: .5;
    margin: 0;
    transition: .3s all ease;
    &:not(:last-child) {
      margin-right: 10px;
    }
    &.is-selected {
      opacity: 1;
    }
  }
  
  @screen lg {
    .flickity-button {
      display: block;
    }
    .flickity-page-dots {
      padding-left: 100px;
      margin-left: auto;
      margin-right: auto;
    } 
  }

  @screen xl {
    .flickity-page-dots {
      width: 900px;
      padding-left: 0;
    }
  }
}