.container {
  width: 1330px;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 25px;
  padding-right: 25px;
}

#main-header .icon-open, #main-header .icon-close {
  transition: all .3s;
}

#main-header .icon-close {
  opacity: 0;
}

#main-header.show-mobile-nav .icon-open {
  opacity: 0;
}

#main-header.show-mobile-nav .icon-close {
  opacity: 1;
}

#main-header.show-virlab-submenu #virlab-submenu, #main-header.show-pocare-submenu #pocare-submenu {
  transform: none;
}

.submenu-mobile {
  transition: all .3s ease-in-out;
  transform: translateX(100%);
}

/*! Flickity v3.0.0
https://flickity.metafizzy.co
---------------------------------------------- */
.flickity-enabled {
  position: relative;
}

.flickity-enabled:focus {
  outline: 0;
}

.flickity-viewport {
  touch-action: pan-y;
  height: 100%;
  position: relative;
  overflow: hidden;
}

.flickity-slider {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
}

.flickity-rtl .flickity-slider {
  left: unset;
  right: 0;
}

.flickity-enabled.is-draggable {
  -webkit-tap-highlight-color: transparent;
  -webkit-user-select: none;
  user-select: none;
}

.flickity-enabled.is-draggable .flickity-viewport {
  cursor: move;
  cursor: grab;
}

.flickity-enabled.is-draggable .flickity-viewport.is-pointer-down {
  cursor: grabbing;
}

.flickity-cell {
  position: absolute;
  left: 0;
}

.flickity-rtl .flickity-cell {
  left: unset;
  right: 0;
}

.flickity-button {
  color: #333;
  background: #ffffffbf;
  border: none;
  position: absolute;
}

.flickity-button:hover {
  cursor: pointer;
  background: #fff;
}

.flickity-button:focus {
  outline: 0;
  box-shadow: 0 0 0 5px #19f;
}

.flickity-button:active {
  opacity: .6;
}

.flickity-button:disabled {
  opacity: .3;
  cursor: auto;
  pointer-events: none;
}

.flickity-button-icon {
  fill: currentColor;
}

.flickity-prev-next-button {
  border-radius: 50%;
  width: 44px;
  height: 44px;
  top: 50%;
  transform: translateY(-50%);
}

.flickity-rtl .flickity-prev-next-button.previous {
  left: auto;
  right: 10px;
}

.flickity-rtl .flickity-prev-next-button.next {
  left: 10px;
  right: auto;
}

.flickity-prev-next-button .flickity-button-icon {
  width: 60%;
  height: 60%;
  position: absolute;
  top: 20%;
  left: 20%;
}

.flickity-page-dots {
  text-align: center;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  display: flex;
  position: absolute;
  bottom: -25px;
}

.flickity-rtl .flickity-page-dots {
  direction: rtl;
}

.flickity-page-dot {
  cursor: pointer;
  -webkit-appearance: none;
  appearance: none;
  text-indent: -9999px;
  background: #33333340;
  border: none;
  border-radius: 50%;
  width: 10px;
  height: 10px;
  margin: 0 8px;
  padding: 0;
  display: block;
  overflow: hidden;
}

.flickity-rtl .flickity-page-dot {
  text-indent: 9999px;
}

.flickity-page-dot:focus {
  outline: 0;
  box-shadow: 0 0 0 5px #19f;
}

.flickity-page-dot.is-selected {
  background: #333;
}

.flickity-page-dots {
  bottom: unset;
  position: relative;
}

.flickity-prev-next-button.previous {
  left: 0;
}

.flickity-prev-next-button.next {
  right: 0;
}

.flickity-prev-next-button {
  background-color: #0000;
  border: 2px solid #fff;
  border-radius: 100px;
  width: 56px;
  height: 35px;
  transition: all .3s;
}

.flickity-prev-next-button svg path {
  fill: #fff;
  transition: all .3s;
}

.flickity-prev-next-button:hover {
  background-color: #fff;
}

.flickity-prev-next-button:hover svg path {
  fill: #035771;
}

#home-testimonials .carousel {
  opacity: 0;
  transition: opacity .3s;
}

#home-testimonials .carousel.is-selected {
  opacity: 1;
}

#home-testimonials .flickity-viewport {
  overflow: visible;
}

#home-testimonials .flickity-button {
  display: none;
}

#home-testimonials .flickity-page-dots {
  text-align: left;
  justify-content: left;
  margin-top: 40px;
}

#home-testimonials .flickity-page-dot {
  opacity: .5;
  background-color: #fff;
  margin: 0;
  transition: all .3s;
}

#home-testimonials .flickity-page-dot:not(:last-child) {
  margin-right: 10px;
}

#home-testimonials .flickity-page-dot.is-selected {
  opacity: 1;
}

@media (min-width: 1024px) {
  #home-testimonials .flickity-button {
    display: block;
  }

  #home-testimonials .flickity-page-dots {
    margin-left: auto;
    margin-right: auto;
    padding-left: 100px;
  }
}

@media (min-width: 1280px) {
  #home-testimonials .flickity-page-dots {
    width: 900px;
    padding-left: 0;
  }
}

@keyframes plyr-progress {
  to {
    background-position: 25px 0;
    background-position: var(--plyr-progress-loading-size, 25px) 0;
  }
}

@keyframes plyr-popup {
  0% {
    opacity: .5;
    transform: translateY(10px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes plyr-fade-in {
  0% {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.plyr {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  direction: ltr;
  font-family: inherit;
  font-family: var(--plyr-font-family, inherit);
  font-variant-numeric: tabular-nums;
  font-weight: 400;
  font-weight: var(--plyr-font-weight-regular, 400);
  line-height: 1.7;
  line-height: var(--plyr-line-height, 1.7);
  text-shadow: none;
  z-index: 0;
  flex-direction: column;
  align-items: center;
  min-width: 200px;
  max-width: 100%;
  transition: box-shadow .3s;
  display: flex;
  position: relative;
}

.plyr audio, .plyr iframe, .plyr video {
  width: 100%;
  height: 100%;
  display: block;
}

.plyr button {
  font: inherit;
  line-height: inherit;
  width: auto;
}

.plyr:focus {
  outline: 0;
}

.plyr--full-ui {
  box-sizing: border-box;
}

.plyr--full-ui *, .plyr--full-ui :after, .plyr--full-ui :before {
  box-sizing: inherit;
}

.plyr--full-ui a, .plyr--full-ui button, .plyr--full-ui input, .plyr--full-ui label {
  touch-action: manipulation;
}

.plyr__badge {
  background: #4a5464;
  background: var(--plyr-badge-background, #4a5464);
  border-radius: 2px;
  border-radius: var(--plyr-badge-border-radius, 2px);
  color: var(--plyr-badge-text-color, #fff);
  font-size: 9px;
  font-size: var(--plyr-font-size-badge, 9px);
  padding: 3px 4px;
  line-height: 1;
}

.plyr--full-ui ::-webkit-media-text-track-container {
  display: none;
}

.plyr__captions {
  font-size: 13px;
  font-size: var(--plyr-font-size-small, 13px);
  padding: 10px;
  padding: var(--plyr-control-spacing, 10px);
  text-align: center;
  width: 100%;
  transition: transform .4s ease-in-out;
  animation: .3s plyr-fade-in;
  display: none;
  position: absolute;
  bottom: 0;
  left: 0;
}

.plyr__captions span:empty {
  display: none;
}

@media (min-width: 480px) {
  .plyr__captions {
    font-size: 15px;
    font-size: var(--plyr-font-size-base, 15px);
    padding: 20px;
    padding: calc(var(--plyr-control-spacing, 10px) * 2);
  }
}

@media (min-width: 768px) {
  .plyr__captions {
    font-size: 18px;
    font-size: var(--plyr-font-size-large, 18px);
  }
}

.plyr--captions-active .plyr__captions {
  display: block;
}

.plyr:not(.plyr--hide-controls) .plyr__controls:not(:empty) ~ .plyr__captions {
  transform: translateY(-40px);
  transform: translateY(calc(var(--plyr-control-spacing, 10px) * -4));
}

.plyr__caption {
  background: #000c;
  background: var(--plyr-captions-background, #000c);
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
  color: var(--plyr-captions-text-color, #fff);
  white-space: pre-wrap;
  border-radius: 2px;
  padding: .2em .5em;
  line-height: 185%;
}

.plyr__caption div {
  display: inline;
}

.plyr__control {
  border-radius: 4px;
  border-radius: var(--plyr-control-radius, 4px);
  color: inherit;
  cursor: pointer;
  padding: 7px;
  padding: calc(var(--plyr-control-spacing, 10px) * .7);
  background: none;
  border: 0;
  flex-shrink: 0;
  transition: all .3s;
  position: relative;
  overflow: visible;
}

.plyr__control svg {
  fill: currentColor;
  height: var(--plyr-control-icon-size, 18px);
  pointer-events: none;
  width: var(--plyr-control-icon-size, 18px);
  width: 18px;
  height: 18px;
  display: block;
}

.plyr__control:focus {
  outline: 0;
}

.plyr__control:focus-visible {
  outline: 2px dashed #00b2ff;
  outline: 2px dashed var(--plyr-focus-visible-color, var(--plyr-color-main, var(--plyr-color-main, #00b2ff)));
  outline-offset: 2px;
}

a.plyr__control {
  text-decoration: none;
}

.plyr__control.plyr__control--pressed .icon--not-pressed, .plyr__control.plyr__control--pressed .label--not-pressed, .plyr__control:not(.plyr__control--pressed) .icon--pressed, .plyr__control:not(.plyr__control--pressed) .label--pressed, a.plyr__control:after, a.plyr__control:before {
  display: none;
}

.plyr--full-ui ::-webkit-media-controls {
  display: none;
}

.plyr__controls {
  text-align: center;
  justify-content: flex-end;
  align-items: center;
  display: flex;
}

.plyr__controls .plyr__progress__container {
  flex: 1;
  min-width: 0;
}

.plyr__controls .plyr__controls__item {
  margin-left: 2.5px;
  margin-left: calc(var(--plyr-control-spacing, 10px) / 4);
}

.plyr__controls .plyr__controls__item:first-child {
  margin-left: 0;
  margin-right: auto;
}

.plyr__controls .plyr__controls__item.plyr__progress__container {
  padding-left: 2.5px;
  padding-left: calc(var(--plyr-control-spacing, 10px) / 4);
}

.plyr__controls .plyr__controls__item.plyr__time {
  padding: 0 5px;
  padding: 0 calc(var(--plyr-control-spacing, 10px) / 2);
}

.plyr__controls .plyr__controls__item.plyr__progress__container:first-child, .plyr__controls .plyr__controls__item.plyr__time + .plyr__time, .plyr__controls .plyr__controls__item.plyr__time:first-child {
  padding-left: 0;
}

.plyr [data-plyr="airplay"], .plyr [data-plyr="captions"], .plyr [data-plyr="fullscreen"], .plyr [data-plyr="pip"], .plyr__controls:empty {
  display: none;
}

.plyr--airplay-supported [data-plyr="airplay"], .plyr--captions-enabled [data-plyr="captions"], .plyr--fullscreen-enabled [data-plyr="fullscreen"], .plyr--pip-supported [data-plyr="pip"] {
  display: inline-block;
}

.plyr__menu {
  display: flex;
  position: relative;
}

.plyr__menu .plyr__control svg {
  transition: transform .3s;
}

.plyr__menu .plyr__control[aria-expanded="true"] svg {
  transform: rotate(90deg);
}

.plyr__menu .plyr__control[aria-expanded="true"] .plyr__tooltip {
  display: none;
}

.plyr__menu__container {
  background: #ffffffe6;
  background: var(--plyr-menu-background, #ffffffe6);
  border-radius: 8px;
  border-radius: var(--plyr-menu-radius, 8px);
  box-shadow: 0 1px 2px #00000026;
  box-shadow: var(--plyr-menu-shadow, 0 1px 2px #00000026);
  color: var(--plyr-menu-color, #4a5464);
  font-size: 15px;
  font-size: var(--plyr-font-size-base, 15px);
  text-align: left;
  white-space: nowrap;
  z-index: 3;
  margin-bottom: 10px;
  animation: .2s plyr-popup;
  position: absolute;
  bottom: 100%;
  right: -3px;
}

.plyr__menu__container > div {
  transition: height .35s cubic-bezier(.4, 0, .2, 1), width .35s cubic-bezier(.4, 0, .2, 1);
  overflow: hidden;
}

.plyr__menu__container:after {
  border: 4px solid #0000;
  border-top-color: #ffffffe6;
  border: var(--plyr-menu-arrow-size, 4px) solid #0000;
  border-top-color: var(--plyr-menu-background, #ffffffe6);
  content: "";
  right: 14px;
  right: calc(var(--plyr-control-icon-size, 18px) / 2 + var(--plyr-control-spacing, 10px) * .7 - var(--plyr-menu-arrow-size, 4px) / 2);
  width: 0;
  height: 0;
  position: absolute;
  top: 100%;
}

.plyr__menu__container [role="menu"] {
  padding: 7px;
  padding: calc(var(--plyr-control-spacing, 10px) * .7);
}

.plyr__menu__container [role="menuitem"], .plyr__menu__container [role="menuitemradio"] {
  margin-top: 2px;
}

.plyr__menu__container [role="menuitem"]:first-child, .plyr__menu__container [role="menuitemradio"]:first-child {
  margin-top: 0;
}

.plyr__menu__container .plyr__control {
  color: var(--plyr-menu-color, #4a5464);
  font-size: 13px;
  font-size: var(--plyr-font-size-menu, var(--plyr-font-size-small, 13px));
  padding: 4.66667px 10.5px;
  padding: calc(var(--plyr-control-spacing, 10px) * .7 / 1.5) calc(var(--plyr-control-spacing, 10px) * .7 * 1.5);
  -webkit-user-select: none;
  user-select: none;
  align-items: center;
  width: 100%;
  display: flex;
}

.plyr__menu__container .plyr__control > span {
  align-items: inherit;
  width: 100%;
  display: flex;
}

.plyr__menu__container .plyr__control:after {
  border: 4px solid #0000;
  border: var(--plyr-menu-item-arrow-size, 4px) solid #0000;
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.plyr__menu__container .plyr__control--forward {
  padding-right: 28px;
  padding-right: calc(var(--plyr-control-spacing, 10px) * .7 * 4);
}

.plyr__menu__container .plyr__control--forward:after {
  border-left-color: #728197;
  border-left-color: var(--plyr-menu-arrow-color, #728197);
  right: 6.5px;
  right: calc(var(--plyr-control-spacing, 10px) * .7 * 1.5 - var(--plyr-menu-item-arrow-size, 4px));
}

.plyr__menu__container .plyr__control--forward:hover:after {
  border-left-color: initial;
}

.plyr__menu__container .plyr__control--forward:focus-visible:after {
  border-left-color: initial;
}

.plyr__menu__container .plyr__control--back {
  font-weight: 400;
  font-weight: var(--plyr-font-weight-regular, 400);
  margin: 7px;
  margin: calc(var(--plyr-control-spacing, 10px) * .7);
  margin-bottom: 3.5px;
  margin-bottom: calc(var(--plyr-control-spacing, 10px) * .7 / 2);
  padding-left: 28px;
  padding-left: calc(var(--plyr-control-spacing, 10px) * .7 * 4);
  width: calc(100% - var(--plyr-control-spacing, 10px) * .7 * 2);
  width: calc(100% - 14px);
  position: relative;
}

.plyr__menu__container .plyr__control--back:after {
  border-right-color: #728197;
  border-right-color: var(--plyr-menu-arrow-color, #728197);
  left: 6.5px;
  left: calc(var(--plyr-control-spacing, 10px) * .7 * 1.5 - var(--plyr-menu-item-arrow-size, 4px));
}

.plyr__menu__container .plyr__control--back:before {
  background: #dcdfe5;
  background: var(--plyr-menu-back-border-color, #dcdfe5);
  box-shadow: 0 1px #fff;
  box-shadow: 0 1px 0 var(--plyr-menu-back-border-shadow-color, #fff);
  content: "";
  margin-top: 3.5px;
  margin-top: calc(var(--plyr-control-spacing, 10px) * .7 / 2);
  height: 1px;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  overflow: hidden;
}

.plyr__menu__container .plyr__control--back:hover:after {
  border-right-color: initial;
}

.plyr__menu__container .plyr__control--back:focus-visible:after {
  border-right-color: initial;
}

.plyr__menu__container .plyr__control[role="menuitemradio"] {
  padding-left: 7px;
  padding-left: calc(var(--plyr-control-spacing, 10px) * .7);
}

.plyr__menu__container .plyr__control[role="menuitemradio"]:after, .plyr__menu__container .plyr__control[role="menuitemradio"]:before {
  border-radius: 100%;
}

.plyr__menu__container .plyr__control[role="menuitemradio"]:before {
  content: "";
  margin-right: 10px;
  margin-right: var(--plyr-control-spacing, 10px);
  background: #0000001a;
  flex-shrink: 0;
  width: 16px;
  height: 16px;
  transition: all .3s;
  display: block;
}

.plyr__menu__container .plyr__control[role="menuitemradio"]:after {
  opacity: 0;
  background: #fff;
  border: 0;
  width: 6px;
  height: 6px;
  transition: transform .3s, opacity .3s;
  top: 50%;
  left: 12px;
  transform: translateY(-50%)scale(0);
}

.plyr__menu__container .plyr__control[role="menuitemradio"][aria-checked="true"]:before {
  background: #00b2ff;
  background: var(--plyr-control-toggle-checked-background, var(--plyr-color-main, var(--plyr-color-main, #00b2ff)));
}

.plyr__menu__container .plyr__control[role="menuitemradio"][aria-checked="true"]:after {
  opacity: 1;
  transform: translateY(-50%)scale(1);
}

.plyr__menu__container .plyr__control[role="menuitemradio"]:hover:before {
  background: #23282f1a;
}

.plyr__menu__container .plyr__control[role="menuitemradio"]:focus-visible:before {
  background: #23282f1a;
}

.plyr__menu__container .plyr__menu__value {
  margin-left: auto;
  margin-right: -5px;
  margin-right: calc(var(--plyr-control-spacing, 10px) * .7 * -1 - -2px);
  padding-left: 24.5px;
  padding-left: calc(var(--plyr-control-spacing, 10px) * .7 * 3.5);
  pointer-events: none;
  align-items: center;
  display: flex;
  overflow: hidden;
}

.plyr--full-ui input[type="range"] {
  -webkit-appearance: none;
  appearance: none;
  border-radius: 26px;
  border-radius: calc(var(--plyr-range-thumb-height, 13px) * 2);
  color: var(--plyr-range-fill-background, var(--plyr-color-main, var(--plyr-color-main, #00b2ff)));
  height: calc(var(--plyr-range-thumb-active-shadow-width, 3px) * 2 + var(--plyr-range-thumb-height, 13px));
  background: none;
  border: 0;
  width: 100%;
  min-width: 0;
  height: 19px;
  margin: 0;
  padding: 0;
  transition: box-shadow .3s;
  display: block;
}

.plyr--full-ui input[type="range"]::-webkit-slider-runnable-track {
  background: linear-gradient(90deg, currentColor 0, #0000 0);
  background-image: linear-gradient(to right, currentColor var(--value, 0), #0000 var(--value, 0));
  border-radius: 2.5px;
  border-radius: calc(var(--plyr-range-track-height, 5px) / 2);
  height: var(--plyr-range-track-height, 5px);
  -webkit-user-select: none;
  user-select: none;
  border: 0;
  height: 5px;
  transition: box-shadow .3s;
}

.plyr--full-ui input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  background: #fff;
  background: var(--plyr-range-thumb-background, #fff);
  box-shadow: 0 1px 1px #23282f26, 0 0 0 1px #23282f33;
  box-shadow: var(--plyr-range-thumb-shadow, 0 1px 1px #23282f26, 0 0 0 1px #23282f33);
  height: var(--plyr-range-thumb-height, 13px);
  margin-top: -4px;
  margin-top: calc((var(--plyr-range-thumb-height, 13px)  - var(--plyr-range-track-height, 5px)) / 2 * -1);
  width: var(--plyr-range-thumb-height, 13px);
  border: 0;
  border-radius: 100%;
  width: 13px;
  height: 13px;
  transition: all .2s;
  position: relative;
}

.plyr--full-ui input[type="range"]::-moz-range-track {
  border-radius: 2.5px;
  border-radius: calc(var(--plyr-range-track-height, 5px) / 2);
  height: var(--plyr-range-track-height, 5px);
  -webkit-user-select: none;
  user-select: none;
  background: none;
  border: 0;
  height: 5px;
  transition: box-shadow .3s;
}

.plyr--full-ui input[type="range"]::-moz-range-thumb {
  background: #fff;
  background: var(--plyr-range-thumb-background, #fff);
  box-shadow: 0 1px 1px #23282f26, 0 0 0 1px #23282f33;
  box-shadow: var(--plyr-range-thumb-shadow, 0 1px 1px #23282f26, 0 0 0 1px #23282f33);
  height: var(--plyr-range-thumb-height, 13px);
  width: var(--plyr-range-thumb-height, 13px);
  border: 0;
  border-radius: 100%;
  width: 13px;
  height: 13px;
  transition: all .2s;
  position: relative;
}

.plyr--full-ui input[type="range"]::-moz-range-progress {
  border-radius: 2.5px;
  border-radius: calc(var(--plyr-range-track-height, 5px) / 2);
  height: var(--plyr-range-track-height, 5px);
  background: currentColor;
  height: 5px;
}

.plyr--full-ui input[type="range"]::-ms-track {
  color: #0000;
}

.plyr--full-ui input[type="range"]::-ms-fill-upper {
  border-radius: 2.5px;
  border-radius: calc(var(--plyr-range-track-height, 5px) / 2);
  height: var(--plyr-range-track-height, 5px);
  -webkit-user-select: none;
  user-select: none;
  background: none;
  border: 0;
  height: 5px;
  transition: box-shadow .3s;
}

.plyr--full-ui input[type="range"]::-ms-track {
  border-radius: 2.5px;
  border-radius: calc(var(--plyr-range-track-height, 5px) / 2);
  height: var(--plyr-range-track-height, 5px);
  -webkit-user-select: none;
  user-select: none;
  background: none;
  border: 0;
  height: 5px;
  transition: box-shadow .3s;
}

.plyr--full-ui input[type="range"]::-ms-fill-lower {
  border-radius: 2.5px;
  border-radius: calc(var(--plyr-range-track-height, 5px) / 2);
  height: var(--plyr-range-track-height, 5px);
  -webkit-user-select: none;
  user-select: none;
  background: currentColor;
  border: 0;
  height: 5px;
  transition: box-shadow .3s;
}

.plyr--full-ui input[type="range"]::-ms-thumb {
  background: #fff;
  background: var(--plyr-range-thumb-background, #fff);
  box-shadow: 0 1px 1px #23282f26, 0 0 0 1px #23282f33;
  box-shadow: var(--plyr-range-thumb-shadow, 0 1px 1px #23282f26, 0 0 0 1px #23282f33);
  height: var(--plyr-range-thumb-height, 13px);
  width: var(--plyr-range-thumb-height, 13px);
  border: 0;
  border-radius: 100%;
  width: 13px;
  height: 13px;
  margin-top: 0;
  transition: all .2s;
  position: relative;
}

.plyr--full-ui input[type="range"]::-ms-tooltip {
  display: none;
}

.plyr--full-ui input[type="range"]::-moz-focus-outer {
  border: 0;
}

.plyr--full-ui input[type="range"]:focus {
  outline: 0;
}

.plyr--full-ui input[type="range"]:focus-visible::-webkit-slider-runnable-track {
  outline: 2px dashed #00b2ff;
  outline: 2px dashed var(--plyr-focus-visible-color, var(--plyr-color-main, var(--plyr-color-main, #00b2ff)));
  outline-offset: 2px;
}

.plyr--full-ui input[type="range"]:focus-visible::-moz-range-track {
  outline: 2px dashed #00b2ff;
  outline: 2px dashed var(--plyr-focus-visible-color, var(--plyr-color-main, var(--plyr-color-main, #00b2ff)));
  outline-offset: 2px;
}

.plyr--full-ui input[type="range"]:focus-visible::-ms-track {
  outline: 2px dashed #00b2ff;
  outline: 2px dashed var(--plyr-focus-visible-color, var(--plyr-color-main, var(--plyr-color-main, #00b2ff)));
  outline-offset: 2px;
}

.plyr__poster {
  background-color: #000;
  background-color: var(--plyr-video-background, var(--plyr-video-background, #000));
  opacity: 0;
  z-index: 1;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: contain;
  width: 100%;
  height: 100%;
  transition: opacity .2s;
  position: absolute;
  top: 0;
  left: 0;
}

.plyr--stopped.plyr__poster-enabled .plyr__poster {
  opacity: 1;
}

.plyr--youtube.plyr--paused.plyr__poster-enabled:not(.plyr--stopped) .plyr__poster {
  display: none;
}

.plyr__time {
  font-size: 13px;
  font-size: var(--plyr-font-size-time, var(--plyr-font-size-small, 13px));
}

.plyr__time + .plyr__time:before {
  content: "⁄";
  margin-right: 10px;
  margin-right: var(--plyr-control-spacing, 10px);
}

@media (max-width: 767px) {
  .plyr__time + .plyr__time {
    display: none;
  }
}

.plyr__tooltip {
  background: #fff;
  background: var(--plyr-tooltip-background, #fff);
  border-radius: 5px;
  border-radius: var(--plyr-tooltip-radius, 5px);
  box-shadow: 0 1px 2px #00000026;
  box-shadow: var(--plyr-tooltip-shadow, 0 1px 2px #00000026);
  color: var(--plyr-tooltip-color, #4a5464);
  font-size: 13px;
  font-size: var(--plyr-font-size-small, 13px);
  font-weight: 400;
  font-weight: var(--plyr-font-weight-regular, 400);
  margin-bottom: 10px;
  margin-bottom: calc(var(--plyr-control-spacing, 10px) / 2 * 2);
  opacity: 0;
  padding: 5px 7.5px;
  padding: calc(var(--plyr-control-spacing, 10px) / 2) calc(var(--plyr-control-spacing, 10px) / 2 * 1.5);
  pointer-events: none;
  transform-origin: 50% 100%;
  white-space: nowrap;
  z-index: 2;
  line-height: 1.3;
  transition: transform .2s .1s, opacity .2s .1s;
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translate(-50%, 10px)scale(.8);
}

.plyr__tooltip:before {
  border-left: 4px solid #0000;
  border-left: var(--plyr-tooltip-arrow-size, 4px) solid #0000;
  border-right: 4px solid #0000;
  border-right: var(--plyr-tooltip-arrow-size, 4px) solid #0000;
  border-top: 4px solid #fff;
  border-top: var(--plyr-tooltip-arrow-size, 4px) solid var(--plyr-tooltip-background, #fff);
  bottom: -4px;
  bottom: calc(var(--plyr-tooltip-arrow-size, 4px) * -1);
  content: "";
  z-index: 2;
  width: 0;
  height: 0;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.plyr .plyr__control:hover .plyr__tooltip, .plyr__tooltip--visible {
  opacity: 1;
  transform: translate(-50%)scale(1);
}

.plyr .plyr__control:focus-visible .plyr__tooltip {
  opacity: 1;
  transform: translate(-50%)scale(1);
}

.plyr .plyr__control:hover .plyr__tooltip {
  z-index: 3;
}

.plyr__controls > .plyr__control:first-child .plyr__tooltip, .plyr__controls > .plyr__control:first-child + .plyr__control .plyr__tooltip {
  transform-origin: 0 100%;
  left: 0;
  transform: translateY(10px)scale(.8);
}

.plyr__controls > .plyr__control:first-child .plyr__tooltip:before, .plyr__controls > .plyr__control:first-child + .plyr__control .plyr__tooltip:before {
  left: 16px;
  left: calc(var(--plyr-control-icon-size, 18px) / 2 + var(--plyr-control-spacing, 10px) * .7);
}

.plyr__controls > .plyr__control:last-child .plyr__tooltip {
  transform-origin: 100% 100%;
  left: auto;
  right: 0;
  transform: translateY(10px)scale(.8);
}

.plyr__controls > .plyr__control:last-child .plyr__tooltip:before {
  left: auto;
  right: 16px;
  right: calc(var(--plyr-control-icon-size, 18px) / 2 + var(--plyr-control-spacing, 10px) * .7);
  transform: translateX(50%);
}

.plyr__controls > .plyr__control:first-child .plyr__tooltip--visible, .plyr__controls > .plyr__control:first-child + .plyr__control .plyr__tooltip--visible, .plyr__controls > .plyr__control:first-child + .plyr__control:hover .plyr__tooltip, .plyr__controls > .plyr__control:first-child:hover .plyr__tooltip, .plyr__controls > .plyr__control:last-child .plyr__tooltip--visible, .plyr__controls > .plyr__control:last-child:hover .plyr__tooltip {
  transform: translate(0)scale(1);
}

.plyr__controls > .plyr__control:first-child + .plyr__control:focus-visible .plyr__tooltip {
  transform: translate(0)scale(1);
}

.plyr__controls > .plyr__control:first-child:focus-visible .plyr__tooltip {
  transform: translate(0)scale(1);
}

.plyr__controls > .plyr__control:last-child:focus-visible .plyr__tooltip {
  transform: translate(0)scale(1);
}

.plyr__progress {
  left: 6.5px;
  left: calc(var(--plyr-range-thumb-height, 13px) * .5);
  margin-right: 13px;
  margin-right: var(--plyr-range-thumb-height, 13px);
  position: relative;
}

.plyr__progress input[type="range"], .plyr__progress__buffer {
  margin-left: -6.5px;
  margin-left: calc(var(--plyr-range-thumb-height, 13px) * -.5);
  margin-right: -6.5px;
  margin-right: calc(var(--plyr-range-thumb-height, 13px) * -.5);
  width: calc(100% + var(--plyr-range-thumb-height, 13px));
  width: calc(100% + 13px);
}

.plyr__progress input[type="range"] {
  z-index: 2;
  position: relative;
}

.plyr__progress .plyr__tooltip {
  overflow-wrap: break-word;
  max-width: 120px;
  left: 0;
}

.plyr__progress__buffer {
  -webkit-appearance: none;
  height: var(--plyr-range-track-height, 5px);
  margin-top: -2.5px;
  margin-top: calc((var(--plyr-range-track-height, 5px) / 2) * -1);
  background: none;
  border: 0;
  border-radius: 100px;
  height: 5px;
  padding: 0;
  position: absolute;
  top: 50%;
  left: 0;
}

.plyr__progress__buffer::-webkit-progress-bar {
  background: none;
}

.plyr__progress__buffer::-webkit-progress-value {
  min-width: var(--plyr-range-track-height, 5px);
  background: currentColor;
  border-radius: 100px;
  min-width: 5px;
  transition: width .2s;
}

.plyr__progress__buffer::-moz-progress-bar {
  min-width: var(--plyr-range-track-height, 5px);
  background: currentColor;
  border-radius: 100px;
  min-width: 5px;
  transition: width .2s;
}

.plyr__progress__buffer::-ms-fill {
  border-radius: 100px;
  transition: width .2s;
}

.plyr--loading .plyr__progress__buffer {
  background-image: linear-gradient(-45deg, #23282f99 25%, #0000 0 50%, #23282f99 0 75%, #0000 0, #0000);
  background-image: linear-gradient(-45deg, var(--plyr-progress-loading-background, #23282f99) 25%, #0000 25%, #0000 50%, var(--plyr-progress-loading-background, #23282f99) 50%, var(--plyr-progress-loading-background, #23282f99) 75%, #0000 75%, #0000);
  background-repeat: repeat-x;
  background-size: 25px 25px;
  background-size: var(--plyr-progress-loading-size, 25px) var(--plyr-progress-loading-size, 25px);
  color: #0000;
  animation: 1s linear infinite plyr-progress;
}

.plyr--video.plyr--loading .plyr__progress__buffer {
  background-color: #ffffff40;
  background-color: var(--plyr-video-progress-buffered-background, #ffffff40);
}

.plyr--audio.plyr--loading .plyr__progress__buffer {
  background-color: #c1c8d199;
  background-color: var(--plyr-audio-progress-buffered-background, #c1c8d199);
}

.plyr__progress__marker {
  background-color: #fff;
  background-color: var(--plyr-progress-marker-background, #fff);
  height: var(--plyr-range-track-height, 5px);
  width: var(--plyr-progress-marker-width, 3px);
  z-index: 3;
  border-radius: 1px;
  width: 3px;
  height: 5px;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}

.plyr__volume {
  align-items: center;
  display: flex;
  position: relative;
}

.plyr__volume input[type="range"] {
  margin-left: 5px;
  margin-left: calc(var(--plyr-control-spacing, 10px) / 2);
  margin-right: 5px;
  margin-right: calc(var(--plyr-control-spacing, 10px) / 2);
  z-index: 2;
  min-width: 60px;
  max-width: 90px;
  position: relative;
}

.plyr--audio {
  display: block;
}

.plyr--audio .plyr__controls {
  background: #fff;
  background: var(--plyr-audio-controls-background, #fff);
  border-radius: inherit;
  color: var(--plyr-audio-control-color, #4a5464);
  padding: 10px;
  padding: var(--plyr-control-spacing, 10px);
}

:is(.plyr--audio .plyr__control:focus-visible, .plyr--audio .plyr__control:hover, .plyr--audio .plyr__control[aria-expanded="true"]) {
  background: #00b2ff;
  background: var(--plyr-audio-control-background-hover, var(--plyr-color-main, var(--plyr-color-main, #00b2ff)));
  color: var(--plyr-audio-control-color-hover, #fff);
}

.plyr--full-ui.plyr--audio input[type="range"]::-webkit-slider-runnable-track {
  background-color: #c1c8d199;
  background-color: var(--plyr-audio-range-track-background, var(--plyr-audio-progress-buffered-background, #c1c8d199));
}

.plyr--full-ui.plyr--audio input[type="range"]::-moz-range-track {
  background-color: #c1c8d199;
  background-color: var(--plyr-audio-range-track-background, var(--plyr-audio-progress-buffered-background, #c1c8d199));
}

.plyr--full-ui.plyr--audio input[type="range"]::-ms-track {
  background-color: #c1c8d199;
  background-color: var(--plyr-audio-range-track-background, var(--plyr-audio-progress-buffered-background, #c1c8d199));
}

.plyr--full-ui.plyr--audio input[type="range"]:active::-webkit-slider-thumb {
  box-shadow: 0 1px 1px #23282f26, 0 0 0 1px #23282f33, 0 0 0 3px #23282f1a;
  box-shadow: var(--plyr-range-thumb-shadow, 0 1px 1px #23282f26, 0 0 0 1px #23282f33), 0 0 0 var(--plyr-range-thumb-active-shadow-width, 3px) var(--plyr-audio-range-thumb-active-shadow-color, #23282f1a);
}

.plyr--full-ui.plyr--audio input[type="range"]:active::-moz-range-thumb {
  box-shadow: 0 1px 1px #23282f26, 0 0 0 1px #23282f33, 0 0 0 3px #23282f1a;
  box-shadow: var(--plyr-range-thumb-shadow, 0 1px 1px #23282f26, 0 0 0 1px #23282f33), 0 0 0 var(--plyr-range-thumb-active-shadow-width, 3px) var(--plyr-audio-range-thumb-active-shadow-color, #23282f1a);
}

.plyr--full-ui.plyr--audio input[type="range"]:active::-ms-thumb {
  box-shadow: 0 1px 1px #23282f26, 0 0 0 1px #23282f33, 0 0 0 3px #23282f1a;
  box-shadow: var(--plyr-range-thumb-shadow, 0 1px 1px #23282f26, 0 0 0 1px #23282f33), 0 0 0 var(--plyr-range-thumb-active-shadow-width, 3px) var(--plyr-audio-range-thumb-active-shadow-color, #23282f1a);
}

.plyr--audio .plyr__progress__buffer {
  color: var(--plyr-audio-progress-buffered-background, #c1c8d199);
}

.plyr--video {
  overflow: hidden;
}

.plyr--video.plyr--menu-open {
  overflow: visible;
}

.plyr__video-wrapper {
  background: #000;
  background: var(--plyr-video-background, var(--plyr-video-background, #000));
  border-radius: inherit;
  width: 100%;
  height: 100%;
  margin: auto;
  position: relative;
  overflow: hidden;
}

.plyr__video-embed, .plyr__video-wrapper--fixed-ratio {
  aspect-ratio: 16 / 9;
}

@supports not (aspect-ratio: 16/9) {
  .plyr__video-embed, .plyr__video-wrapper--fixed-ratio {
    height: 0;
    padding-bottom: 56.25%;
    position: relative;
  }
}

.plyr__video-embed iframe, .plyr__video-wrapper--fixed-ratio video {
  border: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.plyr--full-ui .plyr__video-embed > .plyr__video-embed__container {
  padding-bottom: 240%;
  position: relative;
  transform: translateY(-38.2813%);
}

.plyr--video .plyr__controls {
  background: linear-gradient(#0000, #000000bf);
  background: var(--plyr-video-controls-background, linear-gradient(#0000, #000000bf));
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
  color: var(--plyr-video-control-color, #fff);
  padding: 5px;
  padding: calc(var(--plyr-control-spacing, 10px) / 2);
  padding-top: 20px;
  padding-top: calc(var(--plyr-control-spacing, 10px) * 2);
  z-index: 3;
  transition: opacity .4s ease-in-out, transform .4s ease-in-out;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

@media (min-width: 480px) {
  .plyr--video .plyr__controls {
    padding: 10px;
    padding: var(--plyr-control-spacing, 10px);
    padding-top: 35px;
    padding-top: calc(var(--plyr-control-spacing, 10px) * 3.5);
  }
}

.plyr--video.plyr--hide-controls .plyr__controls {
  opacity: 0;
  pointer-events: none;
  transform: translateY(100%);
}

:is(.plyr--video .plyr__control:focus-visible, .plyr--video .plyr__control:hover, .plyr--video .plyr__control[aria-expanded="true"]) {
  background: #00b2ff;
  background: var(--plyr-video-control-background-hover, var(--plyr-color-main, var(--plyr-color-main, #00b2ff)));
  color: var(--plyr-video-control-color-hover, #fff);
}

.plyr__control--overlaid {
  background: #00b2ff;
  background: var(--plyr-video-control-background-hover, var(--plyr-color-main, var(--plyr-color-main, #00b2ff)));
  color: var(--plyr-video-control-color, #fff);
  opacity: .9;
  padding: 15px;
  padding: calc(var(--plyr-control-spacing, 10px) * 1.5);
  z-index: 2;
  border: 0;
  border-radius: 100%;
  transition: all .3s;
  display: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.plyr__control--overlaid svg {
  position: relative;
  left: 2px;
}

.plyr__control--overlaid:focus, .plyr__control--overlaid:hover {
  opacity: 1;
}

.plyr--playing .plyr__control--overlaid {
  opacity: 0;
  visibility: hidden;
}

.plyr--full-ui.plyr--video .plyr__control--overlaid {
  display: block;
}

.plyr--full-ui.plyr--video input[type="range"]::-webkit-slider-runnable-track {
  background-color: #ffffff40;
  background-color: var(--plyr-video-range-track-background, var(--plyr-video-progress-buffered-background, #ffffff40));
}

.plyr--full-ui.plyr--video input[type="range"]::-moz-range-track {
  background-color: #ffffff40;
  background-color: var(--plyr-video-range-track-background, var(--plyr-video-progress-buffered-background, #ffffff40));
}

.plyr--full-ui.plyr--video input[type="range"]::-ms-track {
  background-color: #ffffff40;
  background-color: var(--plyr-video-range-track-background, var(--plyr-video-progress-buffered-background, #ffffff40));
}

.plyr--full-ui.plyr--video input[type="range"]:active::-webkit-slider-thumb {
  box-shadow: 0 1px 1px #23282f26, 0 0 0 1px #23282f33, 0 0 0 3px #ffffff80;
  box-shadow: var(--plyr-range-thumb-shadow, 0 1px 1px #23282f26, 0 0 0 1px #23282f33), 0 0 0 var(--plyr-range-thumb-active-shadow-width, 3px) var(--plyr-audio-range-thumb-active-shadow-color, #ffffff80);
}

.plyr--full-ui.plyr--video input[type="range"]:active::-moz-range-thumb {
  box-shadow: 0 1px 1px #23282f26, 0 0 0 1px #23282f33, 0 0 0 3px #ffffff80;
  box-shadow: var(--plyr-range-thumb-shadow, 0 1px 1px #23282f26, 0 0 0 1px #23282f33), 0 0 0 var(--plyr-range-thumb-active-shadow-width, 3px) var(--plyr-audio-range-thumb-active-shadow-color, #ffffff80);
}

.plyr--full-ui.plyr--video input[type="range"]:active::-ms-thumb {
  box-shadow: 0 1px 1px #23282f26, 0 0 0 1px #23282f33, 0 0 0 3px #ffffff80;
  box-shadow: var(--plyr-range-thumb-shadow, 0 1px 1px #23282f26, 0 0 0 1px #23282f33), 0 0 0 var(--plyr-range-thumb-active-shadow-width, 3px) var(--plyr-audio-range-thumb-active-shadow-color, #ffffff80);
}

.plyr--video .plyr__progress__buffer {
  color: var(--plyr-video-progress-buffered-background, #ffffff40);
}

.plyr:-webkit-full-screen {
  background: #000;
  width: 100%;
  height: 100%;
  margin: 0;
  border-radius: 0 !important;
}

.plyr:fullscreen {
  background: #000;
  width: 100%;
  height: 100%;
  margin: 0;
  border-radius: 0 !important;
}

.plyr:-webkit-full-screen video {
  height: 100%;
}

.plyr:fullscreen video {
  height: 100%;
}

.plyr:-webkit-full-screen .plyr__control .icon--exit-fullscreen {
  display: block;
}

.plyr:fullscreen .plyr__control .icon--exit-fullscreen {
  display: block;
}

.plyr:-webkit-full-screen .plyr__control .icon--exit-fullscreen + svg {
  display: none;
}

.plyr:fullscreen .plyr__control .icon--exit-fullscreen + svg {
  display: none;
}

.plyr:-webkit-full-screen.plyr--hide-controls {
  cursor: none;
}

.plyr:fullscreen.plyr--hide-controls {
  cursor: none;
}

@media (min-width: 1024px) {
  .plyr:-webkit-full-screen .plyr__captions {
    font-size: 21px;
    font-size: var(--plyr-font-size-xlarge, 21px);
  }

  .plyr:fullscreen .plyr__captions {
    font-size: 21px;
    font-size: var(--plyr-font-size-xlarge, 21px);
  }
}

.plyr--fullscreen-fallback {
  z-index: 10000000;
  background: #000;
  width: 100%;
  height: 100%;
  margin: 0;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: 0 !important;
}

.plyr--fullscreen-fallback video {
  height: 100%;
}

.plyr--fullscreen-fallback .plyr__control .icon--exit-fullscreen {
  display: block;
}

.plyr--fullscreen-fallback .plyr__control .icon--exit-fullscreen + svg {
  display: none;
}

.plyr--fullscreen-fallback.plyr--hide-controls {
  cursor: none;
}

@media (min-width: 1024px) {
  .plyr--fullscreen-fallback .plyr__captions {
    font-size: 21px;
    font-size: var(--plyr-font-size-xlarge, 21px);
  }
}

.plyr__ads {
  border-radius: inherit;
  cursor: pointer;
  z-index: -1;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
}

.plyr__ads > div, .plyr__ads > div iframe {
  width: 100%;
  height: 100%;
  position: absolute;
}

.plyr__ads:after {
  bottom: 10px;
  bottom: var(--plyr-control-spacing, 10px);
  color: #fff;
  content: attr(data-badge-text);
  pointer-events: none;
  right: 10px;
  right: var(--plyr-control-spacing, 10px);
  z-index: 3;
  background: #23282f;
  border-radius: 2px;
  padding: 2px 6px;
  font-size: 11px;
  position: absolute;
}

.plyr__ads:empty:after {
  display: none;
}

.plyr__cues {
  height: var(--plyr-range-track-height, 5px);
  opacity: .8;
  z-index: 3;
  background: currentColor;
  width: 3px;
  height: 5px;
  display: block;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.plyr__preview-thumb {
  background-color: #fff;
  background-color: var(--plyr-tooltip-background, #fff);
  border-radius: 8px;
  border-radius: var(--plyr-menu-radius, 8px);
  box-shadow: 0 1px 2px #00000026;
  box-shadow: var(--plyr-tooltip-shadow, 0 1px 2px #00000026);
  margin-bottom: 10px;
  margin-bottom: calc(var(--plyr-control-spacing, 10px) / 2 * 2);
  opacity: 0;
  pointer-events: none;
  transform-origin: 50% 100%;
  z-index: 2;
  padding: 3px;
  transition: transform .2s .1s, opacity .2s .1s;
  position: absolute;
  bottom: 100%;
  transform: translateY(10px)scale(.8);
}

.plyr__preview-thumb--is-shown {
  opacity: 1;
  transform: translate(0)scale(1);
}

.plyr__preview-thumb:before {
  border-left: 4px solid #0000;
  border-left: var(--plyr-tooltip-arrow-size, 4px) solid #0000;
  border-right: 4px solid #0000;
  border-right: var(--plyr-tooltip-arrow-size, 4px) solid #0000;
  border-top: 4px solid #fff;
  border-top: var(--plyr-tooltip-arrow-size, 4px) solid var(--plyr-tooltip-background, #fff);
  bottom: -4px;
  bottom: calc(var(--plyr-tooltip-arrow-size, 4px) * -1);
  content: "";
  left: calc(50% + var(--preview-arrow-offset));
  z-index: 2;
  width: 0;
  height: 0;
  position: absolute;
  transform: translateX(-50%);
}

.plyr__preview-thumb__image-container {
  border-radius: 7px;
  border-radius: calc(var(--plyr-menu-radius, 8px)  - 1px);
  z-index: 0;
  background: #c1c8d1;
  position: relative;
  overflow: hidden;
}

.plyr__preview-thumb__image-container img, .plyr__preview-thumb__image-container:after {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.plyr__preview-thumb__image-container:after {
  border-radius: inherit;
  content: "";
  pointer-events: none;
  box-shadow: inset 0 0 0 1px #00000026;
}

.plyr__preview-thumb__image-container img {
  max-width: none;
  max-height: none;
}

.plyr__preview-thumb__time-container {
  background: linear-gradient(#0000, #000000bf);
  background: var(--plyr-video-controls-background, linear-gradient(#0000, #000000bf));
  border-bottom-left-radius: 7px;
  border-bottom-left-radius: calc(var(--plyr-menu-radius, 8px)  - 1px);
  border-bottom-right-radius: 7px;
  border-bottom-right-radius: calc(var(--plyr-menu-radius, 8px)  - 1px);
  z-index: 3;
  padding: 20px 6px 6px;
  line-height: 1.1;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.plyr__preview-thumb__time-container span {
  color: #fff;
  font-size: 13px;
  font-size: var(--plyr-font-size-time, var(--plyr-font-size-small, 13px));
}

.plyr__preview-scrubbing {
  filter: blur(1px);
  opacity: 0;
  pointer-events: none;
  z-index: 1;
  width: 100%;
  height: 100%;
  margin: auto;
  transition: opacity .3s;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
}

.plyr__preview-scrubbing--is-shown {
  opacity: 1;
}

.plyr__preview-scrubbing img {
  object-fit: contain;
  width: 100%;
  max-width: none;
  height: 100%;
  max-height: none;
  position: absolute;
  top: 0;
  left: 0;
}

.plyr--no-transition {
  transition: none !important;
}

.plyr__sr-only {
  clip: rect(1px, 1px, 1px, 1px);
  overflow: hidden;
  border: 0 !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  position: absolute !important;
}

.plyr [hidden] {
  display: none !important;
}

.video-popup {
  opacity: 0;
  pointer-events: none;
  transition: all .3s;
}

.video-popup .video-player-wrapper {
  opacity: 0;
  transition: all .3s;
  transform: translateY(50px);
}

.video-popup.is-active {
  opacity: 1;
  pointer-events: auto;
}

.video-popup.is-active .video-player-wrapper {
  opacity: 1;
  transition-delay: .15s;
  transform: none;
}

*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

:before, :after {
  --tw-content: "";
}

html {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-feature-settings: normal;
  font-variation-settings: normal;
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  color: inherit;
  border-top-width: 1px;
  height: 0;
}

abbr:where([title]) {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-feature-settings: inherit;
  font-variation-settings: inherit;
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button {
  height: auto;
}

::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

dialog {
  padding: 0;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden] {
  display: none;
}

*, :before, :after {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

::-webkit-backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

.pointer-events-none {
  pointer-events: none;
}

.fixed {
  position: fixed;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.sticky {
  position: sticky;
}

.inset-0 {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.-bottom-\[3px\] {
  bottom: -3px;
}

.-left-\[\.75\%\] {
  left: -.75%;
}

.-top-\[1px\] {
  top: -1px;
}

.-top-\[3px\] {
  top: -3px;
}

.bottom-0 {
  bottom: 0;
}

.bottom-12 {
  bottom: 3rem;
}

.left-0 {
  left: 0;
}

.left-1\/2 {
  left: 50%;
}

.left-\[15\.8\%\] {
  left: 15.8%;
}

.right-0 {
  right: 0;
}

.right-6 {
  right: 1.5rem;
}

.top-0 {
  top: 0;
}

.top-1\/2 {
  top: 50%;
}

.top-14 {
  top: 3.5rem;
}

.top-6 {
  top: 1.5rem;
}

.top-\[100px\] {
  top: 100px;
}

.top-\[12\%\] {
  top: 12%;
}

.top-\[17\%\] {
  top: 17%;
}

.top-\[1px\] {
  top: 1px;
}

.top-\[20\%\] {
  top: 20%;
}

.top-\[2px\] {
  top: 2px;
}

.z-0 {
  z-index: 0;
}

.z-10 {
  z-index: 10;
}

.z-40 {
  z-index: 40;
}

.z-50 {
  z-index: 50;
}

.z-\[-1\] {
  z-index: -1;
}

.z-\[1\] {
  z-index: 1;
}

.z-\[2\] {
  z-index: 2;
}

.order-first {
  order: -9999;
}

.m-auto {
  margin: auto;
}

.-mx-\[25px\] {
  margin-left: -25px;
  margin-right: -25px;
}

.mx-\[25px\] {
  margin-left: 25px;
  margin-right: 25px;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.my-5 {
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
}

.mb-0 {
  margin-bottom: 0;
}

.mb-1 {
  margin-bottom: .25rem;
}

.mb-10 {
  margin-bottom: 2.5rem;
}

.mb-12 {
  margin-bottom: 3rem;
}

.mb-2 {
  margin-bottom: .5rem;
}

.mb-3 {
  margin-bottom: .75rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.mb-5 {
  margin-bottom: 1.25rem;
}

.mb-6 {
  margin-bottom: 1.5rem;
}

.mb-7 {
  margin-bottom: 1.75rem;
}

.mb-8 {
  margin-bottom: 2rem;
}

.ml-\[10\%\] {
  margin-left: 10%;
}

.ml-\[33\.33\%\] {
  margin-left: 33.33%;
}

.ml-auto {
  margin-left: auto;
}

.mr-2 {
  margin-right: .5rem;
}

.mr-3 {
  margin-right: .75rem;
}

.mt-3 {
  margin-top: .75rem;
}

.mt-4 {
  margin-top: 1rem;
}

.mt-6 {
  margin-top: 1.5rem;
}

.mt-auto {
  margin-top: auto;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.flex {
  display: flex;
}

.inline-flex {
  display: inline-flex;
}

.grid {
  display: grid;
}

.hidden {
  display: none;
}

.aspect-\[3\/2\.5\] {
  aspect-ratio: 3 / 2.5;
}

.aspect-square {
  aspect-ratio: 1;
}

.aspect-video {
  aspect-ratio: 16 / 9;
}

.h-1\/2 {
  height: 50%;
}

.h-\[100px\] {
  height: 100px;
}

.h-\[100svh\] {
  height: 100svh;
}

.h-\[45px\] {
  height: 45px;
}

.h-\[50px\] {
  height: 50px;
}

.h-\[55px\] {
  height: 55px;
}

.h-\[70px\] {
  height: 70px;
}

.h-\[dvh\] {
  height: dvh;
}

.h-full {
  height: 100%;
}

.h-px {
  height: 1px;
}

.h-screen {
  height: 100vh;
}

.w-1\/2 {
  width: 50%;
}

.w-3 {
  width: .75rem;
}

.w-4 {
  width: 1rem;
}

.w-4\/12 {
  width: 33.3333%;
}

.w-\[0\%\] {
  width: 0%;
}

.w-\[100px\] {
  width: 100px;
}

.w-\[10px\] {
  width: 10px;
}

.w-\[110px\] {
  width: 110px;
}

.w-\[15\%\] {
  width: 15%;
}

.w-\[150px\] {
  width: 150px;
}

.w-\[160px\] {
  width: 160px;
}

.w-\[25\%\] {
  width: 25%;
}

.w-\[25px\] {
  width: 25px;
}

.w-\[32\%\] {
  width: 32%;
}

.w-\[33\%\] {
  width: 33%;
}

.w-\[35\%\] {
  width: 35%;
}

.w-\[36\%\] {
  width: 36%;
}

.w-\[40px\] {
  width: 40px;
}

.w-\[45\%\] {
  width: 45%;
}

.w-\[50\%\] {
  width: 50%;
}

.w-\[55\%\] {
  width: 55%;
}

.w-\[58\%\] {
  width: 58%;
}

.w-\[60\%\] {
  width: 60%;
}

.w-\[64\%\] {
  width: 64%;
}

.w-\[65\%\] {
  width: 65%;
}

.w-\[650px\] {
  width: 650px;
}

.w-\[66\%\] {
  width: 66%;
}

.w-\[80\%\] {
  width: 80%;
}

.w-full {
  width: 100%;
}

.origin-left {
  transform-origin: 0;
}

.origin-top-left {
  transform-origin: 0 0;
}

.-translate-x-1\/2 {
  --tw-translate-x: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-x-\[65\%\] {
  --tw-translate-x: -65%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-x-full {
  --tw-translate-x: -100%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-y-1\/2 {
  --tw-translate-y: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-y-\[30\%\] {
  --tw-translate-y: -30%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-x-full {
  --tw-translate-x: 100%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-y-2 {
  --tw-translate-y: .5rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-y-\[15px\] {
  --tw-translate-y: 15px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-rotate-90 {
  --tw-rotate: -90deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.rotate-\[45deg\] {
  --tw-rotate: 45deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.scale-\[1\.085\] {
  --tw-scale-x: 1.085;
  --tw-scale-y: 1.085;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-scale-x-\[1\] {
  --tw-scale-x: -1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.scale-y-\[2\] {
  --tw-scale-y: 2;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.animate-spin-slow {
  animation: 20s linear infinite spin;
}

.animate-spin-slower {
  animation: 40s linear infinite spin;
}

.cursor-auto {
  cursor: auto;
}

.cursor-pointer {
  cursor: pointer;
}

.flex-wrap {
  flex-wrap: wrap;
}

.items-end {
  align-items: flex-end;
}

.items-center {
  align-items: center;
}

.justify-end {
  justify-content: flex-end;
}

.justify-between {
  justify-content: space-between;
}

.gap-3 {
  gap: .75rem;
}

.gap-5 {
  gap: 1.25rem;
}

.gap-7 {
  gap: 1.75rem;
}

.gap-x-16 {
  column-gap: 4rem;
}

.gap-y-4 {
  row-gap: 1rem;
}

.gap-y-8 {
  row-gap: 2rem;
}

.space-x-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1rem * var(--tw-space-x-reverse));
  margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1.25rem * var(--tw-space-x-reverse));
  margin-left: calc(1.25rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-6 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1.5rem * var(--tw-space-x-reverse));
  margin-left: calc(1.5rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-8 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(2rem * var(--tw-space-x-reverse));
  margin-left: calc(2rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-\[20px\] > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(20px * var(--tw-space-x-reverse));
  margin-left: calc(20px * calc(1 - var(--tw-space-x-reverse)));
}

.space-y-1 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(.25rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(.25rem * var(--tw-space-y-reverse));
}

.space-y-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(.5rem * var(--tw-space-y-reverse));
}

.space-y-3 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(.75rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(.75rem * var(--tw-space-y-reverse));
}

.space-y-5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1.25rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1.25rem * var(--tw-space-y-reverse));
}

.space-y-8 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(2rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(2rem * var(--tw-space-y-reverse));
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-scroll {
  overflow: scroll;
}

.overflow-x-hidden {
  overflow-x: hidden;
}

.overflow-y-hidden {
  overflow-y: hidden;
}

.scroll-smooth {
  scroll-behavior: smooth;
}

.rounded-2xl {
  border-radius: 1rem;
}

.rounded-full {
  border-radius: 9999px;
}

.rounded-md {
  border-radius: .375rem;
}

.rounded-bl-full {
  border-bottom-left-radius: 9999px;
}

.rounded-br-full {
  border-bottom-right-radius: 9999px;
}

.rounded-tl-full {
  border-top-left-radius: 9999px;
}

.rounded-tr-full {
  border-top-right-radius: 9999px;
}

.border-2 {
  border-width: 2px;
}

.border-b {
  border-bottom-width: 1px;
}

.border-l {
  border-left-width: 1px;
}

.border-r {
  border-right-width: 1px;
}

.border-t {
  border-top-width: 1px;
}

.border-\[\#ddd\] {
  --tw-border-opacity: 1;
  border-color: rgb(221 221 221 / var(--tw-border-opacity));
}

.border-\[\#eee\] {
  --tw-border-opacity: 1;
  border-color: rgb(238 238 238 / var(--tw-border-opacity));
}

.border-gray-dark {
  --tw-border-opacity: 1;
  border-color: rgb(88 88 88 / var(--tw-border-opacity));
}

.border-white {
  --tw-border-opacity: 1;
  border-color: rgb(255 255 255 / var(--tw-border-opacity));
}

.border-opacity-25 {
  --tw-border-opacity: .25;
}

.bg-\[\#6FB0C7\] {
  --tw-bg-opacity: 1;
  background-color: rgb(111 176 199 / var(--tw-bg-opacity));
}

.bg-black {
  --tw-bg-opacity: 1;
  background-color: rgb(0 0 0 / var(--tw-bg-opacity));
}

.bg-blue {
  --tw-bg-opacity: 1;
  background-color: rgb(3 87 113 / var(--tw-bg-opacity));
}

.bg-gray-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(209 213 219 / var(--tw-bg-opacity));
}

.bg-gray-dark {
  --tw-bg-opacity: 1;
  background-color: rgb(88 88 88 / var(--tw-bg-opacity));
}

.bg-gray-light {
  --tw-bg-opacity: 1;
  background-color: rgb(246 245 244 / var(--tw-bg-opacity));
}

.bg-orange {
  --tw-bg-opacity: 1;
  background-color: rgb(246 92 0 / var(--tw-bg-opacity));
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.bg-opacity-25 {
  --tw-bg-opacity: .25;
}

.bg-opacity-50 {
  --tw-bg-opacity: .5;
}

.bg-opacity-75 {
  --tw-bg-opacity: .75;
}

.bg-gradient-to-l {
  background-image: linear-gradient(to left, var(--tw-gradient-stops));
}

.bg-gradient-to-r {
  background-image: linear-gradient(to right, var(--tw-gradient-stops));
}

.from-blue {
  --tw-gradient-from: #035771 var(--tw-gradient-from-position);
  --tw-gradient-to: #03577100 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.to-blue-light {
  --tw-gradient-to: #6fb0c7 var(--tw-gradient-to-position);
}

.fill-none {
  fill: none;
}

.stroke-current {
  stroke: currentColor;
}

.stroke-gray-dark {
  stroke: #585858;
}

.stroke-orange {
  stroke: #f65c00;
}

.object-cover {
  object-fit: cover;
}

.p-6 {
  padding: 1.5rem;
}

.p-7 {
  padding: 1.75rem;
}

.p-8 {
  padding: 2rem;
}

.p-\[6px\] {
  padding: 6px;
}

.p-\[7px\] {
  padding: 7px;
}

.p-\[8\.85\%\] {
  padding: 8.85%;
}

.p-\[8px\] {
  padding: 8px;
}

.px-10 {
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}

.px-5 {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.px-8 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.px-\[25px\] {
  padding-left: 25px;
  padding-right: 25px;
}

.py-10 {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}

.py-12 {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.py-16 {
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.py-5 {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}

.py-8 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.py-\[9\.25px\] {
  padding-top: 9.25px;
  padding-bottom: 9.25px;
}

.pb-10 {
  padding-bottom: 2.5rem;
}

.pb-14 {
  padding-bottom: 3.5rem;
}

.pb-5 {
  padding-bottom: 1.25rem;
}

.pb-6 {
  padding-bottom: 1.5rem;
}

.pb-\[15px\] {
  padding-bottom: 15px;
}

.pb-\[18px\] {
  padding-bottom: 18px;
}

.pb-\[3px\] {
  padding-bottom: 3px;
}

.pb-\[4px\] {
  padding-bottom: 4px;
}

.pb-\[5px\] {
  padding-bottom: 5px;
}

.pl-0 {
  padding-left: 0;
}

.pl-\[25px\] {
  padding-left: 25px;
}

.pt-2 {
  padding-top: .5rem;
}

.pt-4 {
  padding-top: 1rem;
}

.pt-5 {
  padding-top: 1.25rem;
}

.pt-8 {
  padding-top: 2rem;
}

.pt-\[10px\] {
  padding-top: 10px;
}

.pt-\[12px\] {
  padding-top: 12px;
}

.pt-\[3px\] {
  padding-top: 3px;
}

.pt-\[4px\] {
  padding-top: 4px;
}

.pt-\[70px\] {
  padding-top: 70px;
}

.text-center {
  text-align: center;
}

.font-body {
  font-family: Arboria, sans-serif;
}

.text-\[13px\] {
  font-size: 13px;
}

.text-\[14px\] {
  font-size: 14px;
}

.text-\[15px\] {
  font-size: 15px;
}

.text-\[18px\] {
  font-size: 18px;
}

.text-\[22px\] {
  font-size: 22px;
}

.text-\[28px\] {
  font-size: 28px;
}

.text-\[30px\] {
  font-size: 30px;
}

.text-\[33px\] {
  font-size: 33px;
}

.text-\[40px\] {
  font-size: 40px;
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.text-sm {
  font-size: .875rem;
  line-height: 1.25rem;
}

.text-xs {
  font-size: .75rem;
  line-height: 1rem;
}

.font-bold {
  font-weight: 700;
}

.font-normal {
  font-weight: 400;
}

.font-thin {
  font-weight: 100;
}

.uppercase {
  text-transform: uppercase;
}

.normal-case {
  text-transform: none;
}

.leading-\[1\.2\] {
  line-height: 1.2;
}

.leading-\[1\.5\] {
  line-height: 1.5;
}

.leading-\[1\.75\] {
  line-height: 1.75;
}

.leading-\[35px\] {
  line-height: 35px;
}

.leading-\[40px\] {
  line-height: 40px;
}

.leading-\[42px\] {
  line-height: 42px;
}

.leading-\[50px\] {
  line-height: 50px;
}

.leading-none {
  line-height: 1;
}

.leading-snug {
  line-height: 1.375;
}

.leading-tight {
  line-height: 1.25;
}

.tracking-wide {
  letter-spacing: .025em;
}

.tracking-widest {
  letter-spacing: .1em;
}

.text-gray-dark {
  --tw-text-opacity: 1;
  color: rgb(88 88 88 / var(--tw-text-opacity));
}

.text-orange {
  --tw-text-opacity: 1;
  color: rgb(246 92 0 / var(--tw-text-opacity));
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.antialiased {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.opacity-0 {
  opacity: 0;
}

.opacity-25 {
  opacity: .25;
}

.opacity-30 {
  opacity: .3;
}

.opacity-75 {
  opacity: .75;
}

.shadow-lg {
  --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -4px #0000001a;
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-md {
  --tw-shadow: 0 4px 6px -1px #0000001a, 0 2px 4px -2px #0000001a;
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.backdrop-blur {
  --tw-backdrop-blur: blur(8px);
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.transition {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-all {
  transition-property: all;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.delay-100 {
  transition-delay: .1s;
}

.duration-300 {
  transition-duration: .3s;
}

.ease-in-out {
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.ease-out {
  transition-timing-function: cubic-bezier(0, 0, .2, 1);
}

.before\:absolute:before {
  content: var(--tw-content);
  position: absolute;
}

.before\:bottom-0:before {
  content: var(--tw-content);
  bottom: 0;
}

.before\:left-\[25px\]:before {
  content: var(--tw-content);
  left: 25px;
}

.before\:right-\[25px\]:before {
  content: var(--tw-content);
  right: 25px;
}

.before\:block:before {
  content: var(--tw-content);
  display: block;
}

.before\:h-px:before {
  content: var(--tw-content);
  height: 1px;
}

.before\:bg-\[\#ddd\]:before {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: rgb(221 221 221 / var(--tw-bg-opacity));
}

.first\:pt-0:first-child {
  padding-top: 0;
}

.last\:border-b-0:last-child {
  border-bottom-width: 0;
}

.last\:pb-0:last-child {
  padding-bottom: 0;
}

.last\:before\:hidden:last-child:before {
  content: var(--tw-content);
  display: none;
}

.hover\:-translate-y-2:hover {
  --tw-translate-y: -.5rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.hover\:-translate-y-3:hover {
  --tw-translate-y: -.75rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.hover\:bg-\[\#eee\]:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(238 238 238 / var(--tw-bg-opacity));
}

.hover\:bg-orange:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(246 92 0 / var(--tw-bg-opacity));
}

.hover\:text-orange:hover {
  --tw-text-opacity: 1;
  color: rgb(246 92 0 / var(--tw-text-opacity));
}

.hover\:opacity-80:hover {
  opacity: .8;
}

.hover\:opacity-90:hover {
  opacity: .9;
}

.hover\:shadow-lg:hover {
  --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -4px #0000001a;
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.group:hover .group-hover\:pointer-events-auto {
  pointer-events: auto;
}

.group:hover .group-hover\:left-0 {
  left: 0;
}

.group:hover .group-hover\:w-full {
  width: 100%;
}

.group\/item:hover .group-hover\/item\:translate-x-1 {
  --tw-translate-x: .25rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.group:hover .group-hover\:-translate-y-2 {
  --tw-translate-y: -.5rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.group:hover .group-hover\:translate-x-\[145\%\] {
  --tw-translate-x: 145%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.group:hover .group-hover\:translate-y-0 {
  --tw-translate-y: 0px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.group:hover .group-hover\:scale-\[1\.1\] {
  --tw-scale-x: 1.1;
  --tw-scale-y: 1.1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.group:hover .group-hover\:scale-\[1\.25\] {
  --tw-scale-x: 1.25;
  --tw-scale-y: 1.25;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.group:hover .group-hover\:-scale-y-\[1\] {
  --tw-scale-y: -1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.group:hover .group-hover\:fill-white {
  fill: #fff;
}

.group\/item:hover .group-hover\/item\:stroke-orange, .group:hover .group-hover\:stroke-orange {
  stroke: #f65c00;
}

.group\/item:hover .group-hover\/item\:text-orange, .group:hover .group-hover\:text-orange {
  --tw-text-opacity: 1;
  color: rgb(246 92 0 / var(--tw-text-opacity));
}

.group:hover .group-hover\:opacity-0 {
  opacity: 0;
}

.group:hover .group-hover\:opacity-100 {
  opacity: 1;
}

.group:hover .group-hover\:transition {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.group:hover .group-hover\:duration-1000 {
  transition-duration: 1s;
}

.group:hover .group-hover\:ease-in-out-circ {
  transition-timing-function: cubic-bezier(.85, 0, .15, 1);
}

@media (min-width: 768px) {
  .md\:mx-0 {
    margin-left: 0;
    margin-right: 0;
  }

  .md\:mb-0 {
    margin-bottom: 0;
  }

  .md\:mb-14 {
    margin-bottom: 3.5rem;
  }

  .md\:mt-4 {
    margin-top: 1rem;
  }

  .md\:h-\[100px\] {
    height: 100px;
  }

  .md\:w-10\/12 {
    width: 83.3333%;
  }

  .md\:w-8\/12 {
    width: 66.6667%;
  }

  .md\:w-\[15\%\] {
    width: 15%;
  }

  .md\:w-\[150px\] {
    width: 150px;
  }

  .md\:w-\[20\%\] {
    width: 20%;
  }

  .md\:w-\[40\%\] {
    width: 40%;
  }

  .md\:w-\[50px\] {
    width: 50px;
  }

  .md\:w-\[60\%\] {
    width: 60%;
  }

  .md\:w-\[80\%\] {
    width: 80%;
  }

  .md\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .md\:gap-4 {
    gap: 1rem;
  }

  .md\:gap-8 {
    gap: 2rem;
  }

  .md\:space-x-6 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1.5rem * var(--tw-space-x-reverse));
    margin-left: calc(1.5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:rounded-xl {
    border-radius: .75rem;
  }

  .md\:p-10 {
    padding: 2.5rem;
  }

  .md\:p-\[7\%\] {
    padding: 7%;
  }

  .md\:px-12 {
    padding-left: 3rem;
    padding-right: 3rem;
  }

  .md\:px-14 {
    padding-left: 3.5rem;
    padding-right: 3.5rem;
  }

  .md\:px-16 {
    padding-left: 4rem;
    padding-right: 4rem;
  }

  .md\:py-14 {
    padding-top: 3.5rem;
    padding-bottom: 3.5rem;
  }

  .md\:py-16 {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }

  .md\:pb-16 {
    padding-bottom: 4rem;
  }

  .md\:pl-16 {
    padding-left: 4rem;
  }

  .md\:pr-0 {
    padding-right: 0;
  }

  .md\:pt-14 {
    padding-top: 3.5rem;
  }

  .md\:pt-\[100px\] {
    padding-top: 100px;
  }

  .md\:text-\[28px\] {
    font-size: 28px;
  }

  .md\:text-\[40px\] {
    font-size: 40px;
  }

  .md\:text-\[64px\] {
    font-size: 64px;
  }

  .md\:text-lg {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }
}

@media (min-width: 1024px) {
  .lg\:static {
    position: static;
  }

  .lg\:absolute {
    position: absolute;
  }

  .lg\:left-\[unset\] {
    left: unset;
  }

  .lg\:right-0 {
    right: 0;
  }

  .lg\:order-none {
    order: 0;
  }

  .lg\:mb-0 {
    margin-bottom: 0;
  }

  .lg\:mb-16 {
    margin-bottom: 4rem;
  }

  .lg\:mt-10 {
    margin-top: 2.5rem;
  }

  .lg\:block {
    display: block;
  }

  .lg\:flex {
    display: flex;
  }

  .lg\:hidden {
    display: none;
  }

  .lg\:h-auto {
    height: auto;
  }

  .lg\:w-1\/2 {
    width: 50%;
  }

  .lg\:w-\[40\%\] {
    width: 40%;
  }

  .lg\:w-\[60\%\] {
    width: 60%;
  }

  .lg\:w-\[700px\] {
    width: 700px;
  }

  .lg\:w-\[800px\] {
    width: 800px;
  }

  .lg\:w-\[90\%\] {
    width: 90%;
  }

  .lg\:w-\[960px\] {
    width: 960px;
  }

  .lg\:w-auto {
    width: auto;
  }

  .lg\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .lg\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .lg\:gap-20 {
    gap: 5rem;
  }

  .lg\:space-x-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(2rem * var(--tw-space-x-reverse));
    margin-left: calc(2rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:rounded-xl {
    border-radius: .75rem;
  }

  .lg\:p-12 {
    padding: 3rem;
  }

  .lg\:px-0 {
    padding-left: 0;
    padding-right: 0;
  }

  .lg\:px-\[100px\] {
    padding-left: 100px;
    padding-right: 100px;
  }

  .lg\:py-0 {
    padding-top: 0;
    padding-bottom: 0;
  }

  .lg\:py-16 {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }

  .lg\:py-24 {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }

  .lg\:py-8 {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  .lg\:pb-24 {
    padding-bottom: 6rem;
  }

  .lg\:pl-10 {
    padding-left: 2.5rem;
  }

  .lg\:pr-12 {
    padding-right: 3rem;
  }

  .lg\:pr-16 {
    padding-right: 4rem;
  }

  .lg\:pr-20 {
    padding-right: 5rem;
  }

  .lg\:text-\[46px\] {
    font-size: 46px;
  }

  .lg\:text-\[50px\] {
    font-size: 50px;
  }
}

@media (min-width: 1280px) {
  .xl\:hidden {
    display: none;
  }

  .xl\:w-\[900px\] {
    width: 900px;
  }

  .xl\:grid-cols-5 {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }

  .xl\:gap-28 {
    gap: 7rem;
  }

  .xl\:rounded-3xl {
    border-radius: 1.5rem;
  }

  .xl\:px-0 {
    padding-left: 0;
    padding-right: 0;
  }

  .xl\:pr-24 {
    padding-right: 6rem;
  }
}

.\[\&\>\*\]\:w-full > * {
  width: 100%;
}

.\[\&\>a\]\:bg-orange > a {
  --tw-bg-opacity: 1;
  background-color: rgb(246 92 0 / var(--tw-bg-opacity));
}

.\[\&\>a\]\:bg-white > a {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.\[\&\>a\]\:text-orange > a {
  --tw-text-opacity: 1;
  color: rgb(246 92 0 / var(--tw-text-opacity));
}

.\[\&\>a\]\:text-white > a {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.\[\&\>div\]\:text-orange > div {
  --tw-text-opacity: 1;
  color: rgb(246 92 0 / var(--tw-text-opacity));
}

.\[\&\>li\>div\>a\]\:text-center > li > div > a, .\[\&\>li\>div\>span\]\:text-center > li > div > span {
  text-align: center;
}

.\[\&\>li\>div\]\:w-\[max-content\] > li > div {
  width: max-content;
}

.\[\&_a\]\:block a {
  display: block;
}

.\[\&_a\]\:h-\[30px\] a {
  height: 30px;
}

.\[\&_a\]\:w-\[30px\] a {
  width: 30px;
}

.\[\&_a\]\:rounded-full a {
  border-radius: 9999px;
}

.\[\&_a\]\:border a {
  border-width: 1px;
}

.\[\&_a\]\:border-orange a {
  --tw-border-opacity: 1;
  border-color: rgb(246 92 0 / var(--tw-border-opacity));
}

.\[\&_a\]\:transition a {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.\[\&_path\]\:fill-orange path {
  fill: #f65c00;
}

/*# sourceMappingURL=index.01650169.css.map */
